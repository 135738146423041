import React, { memo, useEffect, useState } from 'react';
import style from './DataSetsTable.module.scss';
import PropTypes from 'prop-types';
import { formatStringNumberWithCommas } from 'Utils';

/**
 * DataSetsTable.jsx
 *
 * @summary This component is to construct and style the tables for data sets page.
 *
 * @param {Object} props - Component props.
 * @prop {{id: String, columns: Array, rows: Array, type: String}} webTableData - Web data table scheme (containing rows and columns) to render table
 * @prop {{id: String, type: String, entity: String, reportingPeriod: String, dataFields: Object}[]} props.tableData - Data that is rendered in the table
 * @prop {Boolean} [props.isFixedTable] - If given as true, renders as fixed table, otherwise, renders as 'append_rows'
 * @prop {Boolean} [props.expandAllCells] - If given as true, sets all cells as expanded, otherwise minimises the cells
 * @prop {Boolean} [props.updateParentData] - Used to update parent data after specific cell is expanded
 * @prop {String} [props.defaultValue] - Default value for data table if no data is found
 */
const DataSetsTable = memo(function DataSetsTable({ webTableData, tableData, isFixedTable, expandAllCells, updateParentData, defaultValue }) {
  const [expandableCells, setExpandableCells] = useState({});

  useEffect(() => {
    const updatedExpandCells = { ...expandableCells };
    Object.keys(updatedExpandCells).forEach((cellKey) => {
      updatedExpandCells[cellKey] = { ...updatedExpandCells[cellKey], expanded: expandAllCells };
    });
    setExpandableCells(updatedExpandCells);
    if (updateParentData) {
      updateParentData();
    }
  }, [expandAllCells]);

  useEffect(() => {
    if (updateParentData) {
      updateParentData();
    }
  }, [expandableCells, webTableData]);

  useEffect(() => {
    // updates all expandable cells data (if necessary)
    const currentExpandableCells = {};
    tableData?.forEach((specificEntityYearData, entityYearIndex) => {
      if (specificEntityYearData?.datafields && Array.isArray(specificEntityYearData?.datafields))
        specificEntityYearData?.datafields?.forEach((_, rowIndex) => {
          webTableData?.columns?.forEach((colGroup) => {
            if (colGroup?.subColumns?.length > 0) {
              colGroup?.subColumns?.forEach((col) => {
                const foundCell = document.getElementById(`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`);
                if (foundCell) {
                  if (foundCell.scrollHeight !== foundCell.clientHeight) {
                    currentExpandableCells[`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`] = { expanded: false };
                  }
                }
              });
            } else {
              const foundCell = document.getElementById(`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`);
              if (foundCell) {
                if (foundCell.scrollHeight !== foundCell.clientHeight) {
                  currentExpandableCells[`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`] = { expanded: false };
                }
              }
            }
          });
        });
    });
    setExpandableCells(currentExpandableCells);
  }, []);

  const fixedTableEntityHeading = () => {
    const entityColSpanInfo = { '': 1 };
    tableData?.forEach((entityData) => {
      let totalColumns = 0;
      webTableData?.columns?.forEach((colGroup) => {
        if (colGroup?.subColumns && colGroup?.subColumns?.length > 0) {
          totalColumns += colGroup?.subColumns?.length || 0;
        } else {
          totalColumns += 1;
        }
      });
      if (entityColSpanInfo[entityData.entity]) {
        entityColSpanInfo[entityData.entity] += 1 * (totalColumns || 1);
      } else {
        entityColSpanInfo[entityData.entity] = 1 * (totalColumns || 1);
      }
    });

    return Object.keys(entityColSpanInfo).map((entityName, index) => {
      return (
        <th scope="colgroup" className={index % 2 === 0 ? style.whiteBackground : style.greenBackground} key={`${entityName}-${index}`} colSpan={entityColSpanInfo[entityName]}>
          {entityName}
        </th>
      );
    });
  };

  const fixedTableReportingYearHeading = () => {
    const emptyFirstCell = [{ entity: 'empty-cell', reportingPeriod: '' }];
    let previousEntity = 'empty-cell';
    let backgroundGreenColor = false;
    return emptyFirstCell.concat(tableData).map((entityYearData, index) => {
      if (previousEntity !== entityYearData.entity) {
        previousEntity = entityYearData.entity;
        backgroundGreenColor = !backgroundGreenColor;
      }

      let totalColumns = 0;
      webTableData?.columns?.forEach((colGroup) => {
        if (colGroup?.subColumns && colGroup?.subColumns?.length > 0) {
          totalColumns += colGroup?.subColumns?.length || 0;
        } else {
          totalColumns += 1;
        }
      });
      return (
        <th
          scope="colgroup"
          className={backgroundGreenColor ? style.greenBackground : style.whiteBackground}
          key={`${entityYearData.entity}-${entityYearData.reportingPeriod}-${index}`}
          colSpan={index === 0 ? 1 : totalColumns || 1}>
          {entityYearData.reportingPeriod}
        </th>
      );
    });
  };

  const fixedTableColumnGroupHeading = () => {
    if (webTableData?.columns?.length > 0) {
      let previousEntity = '';
      let backgroundGreenColor = false;

      return (
        <>
          {/* empty cell at the start of each column heading group */}
          {<th></th>}
          {tableData?.map((entityYearData) => {
            if (previousEntity !== entityYearData.entity) {
              previousEntity = entityYearData.entity;
              backgroundGreenColor = !backgroundGreenColor;
            }
            return webTableData?.columns?.map((colGroup, index) => {
              return (
                <th
                  style={colGroup.width ? { minWidth: colGroup.width } : {}}
                  className={backgroundGreenColor ? style.greenBackground : style.whiteBackground}
                  key={`${colGroup.display}-${index}`}
                  scope="colgroup"
                  colSpan={colGroup?.subColumns?.length || 1}>
                  {colGroup.display}
                </th>
              );
            });
          })}
        </>
      );
    }
  };

  const fixedTableColumnHeading = () => {
    let backgroundGreenColor = false;
    let previousEntity = '';
    return (
      <>
        {/* empty cell at the start of each column heading group */}
        {<th></th>}
        {tableData?.map((entityYearData) => {
          if (previousEntity !== entityYearData.entity) {
            previousEntity = entityYearData.entity;
            backgroundGreenColor = !backgroundGreenColor;
          }
          return webTableData?.columns?.map((colGroup) => {
            return colGroup?.subColumns?.map((col) => {
              return (
                <th
                  className={backgroundGreenColor ? style.greenBackground : style.whiteBackground}
                  key={`${col.display}`}
                  style={col.width ? { minWidth: col.width } : {}}
                  scope="col">
                  {col.display}
                </th>
              );
            });
          });
        })}
      </>
    );
  };

  if (isFixedTable) {
    return (
      <table className={style.dataSetTable}>
        <thead>
          <tr>{fixedTableEntityHeading()}</tr>
          <tr>{fixedTableReportingYearHeading()}</tr>
          <tr>{fixedTableColumnGroupHeading()}</tr>
          {webTableData?.columns?.some((col) => col?.subColumns?.length > 0) && <tr>{fixedTableColumnHeading()}</tr>}
        </thead>
        <tbody>
          {webTableData.rows.map((row, index) => {
            let backgroundGreenColor = false;
            let previousEntity = '';

            return (
              <tr key={`${row.display}-${index}`}>
                <th scope="row" style={row.width ? { minWidth: row.width } : {}}>
                  {row.display}
                </th>
                {tableData.map((entityYearData) => {
                  if (previousEntity !== entityYearData.entity) {
                    previousEntity = entityYearData.entity;
                    backgroundGreenColor = !backgroundGreenColor;
                  }
                  if (webTableData.columns?.length > 0) {
                    return webTableData.columns.map((colGroup) => {
                      if (colGroup?.subColumns?.length > 0) {
                        return colGroup?.subColumns?.map((col, colIndex) => {
                          return (
                            <td className={backgroundGreenColor ? style.greenBackground : style.whiteBackground} key={`${colGroup.display}-${col.display}-${colIndex}`}>
                              {/* only gets the col.key value added when available (data will come back as 'Row|Colgroup-Col' or 'Row|Col') */}
                              {/* if is row heading, don't render any values here */}
                              {row.isRowHeading
                                ? ''
                                : formatStringNumberWithCommas(
                                    entityYearData.datafields?.[
                                      `${row.key?.toLowerCase()}${row.key ? '|' : ''}${colGroup.key?.toLowerCase()}${col.key ? `-${col.key?.toLowerCase()}` : ''}`
                                    ],
                                  ) || defaultValue}
                            </td>
                          );
                        });
                      }
                      return (
                        <td className={backgroundGreenColor ? style.greenBackground : style.whiteBackground} key={`${colGroup.display}`}>
                          {/* if is row heading, don't render any values here */}
                          {row.isRowHeading
                            ? ''
                            : formatStringNumberWithCommas(entityYearData.datafields?.[`${row.key?.toLowerCase()}${row.key ? '|' : ''}${colGroup.key?.toLowerCase()}`]) ||
                              defaultValue}
                        </td>
                      );
                    });
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const appendTableColumnsHeading = () => {
    return webTableData?.columns?.map((colGroup, index) => {
      return (
        <th scope="col" colSpan={colGroup?.subColumns?.length || 1} style={colGroup.width ? { minWidth: colGroup.width } : {}} key={`${colGroup.codename}-${index}`}>
          {colGroup.display}
        </th>
      );
    });
  };

  const appendTableSubColumnsHeading = () => {
    return webTableData?.columns?.map((colGroup) => {
      return colGroup?.subColumns?.map((col, index) => {
        return (
          <th key={`${col.codename}-${colGroup.codename}-${index}`} style={col.width ? { minWidth: col.width } : {}}>
            {col.display}
          </th>
        );
      });
    });
  };

  const expandCell = (cellKey) => {
    if (expandableCells[cellKey]) {
      const updatedCells = { ...expandableCells };
      updatedCells[cellKey].expanded = !updatedCells[cellKey].expanded;
      setExpandableCells(updatedCells);
    }
  };

  const renderAppendTableRows = () => {
    let currentEntity = '';
    let backgroundGreenColor = false;

    return tableData?.map((specificEntityYearData, entityYearIndex) => {
      if (specificEntityYearData.entity !== currentEntity) {
        currentEntity = specificEntityYearData.entity;
        backgroundGreenColor = !backgroundGreenColor;
      }

      return specificEntityYearData?.datafields?.map((rowData, rowIndex) => {
        return (
          <tr key={`${specificEntityYearData.entity}-${specificEntityYearData.reportingPeriod}-row-data-${rowIndex}`}>
            {webTableData?.columns?.map((colGroup) => {
              if (colGroup?.subColumns?.length > 0) {
                return colGroup?.subColumns?.map((col) => {
                  return (
                    <td key={`${col.codename}-${rowIndex}`} className={backgroundGreenColor ? style.greenBackground : style.whiteBackground}>
                      <span
                        id={`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`}
                        className={style.limitTextLines}
                        style={expandableCells[`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`]?.expanded ? { '--MAX-LINE': 'none' } : {}}>
                        {col.concatFields && Array.isArray(col.concatFields)
                          ? // Use 'Name' value if available, otherwise use concat fields
                            rowData['name'] || col.concatFields.map((fieldName) => rowData[fieldName?.toLowerCase()]).join(' ')
                          : col.key?.toLowerCase() !== 'year' && col.key?.toLowerCase() !== 'organisation abn'
                          ? formatStringNumberWithCommas(rowData[col.key?.toLowerCase()]) || defaultValue
                          : rowData[col.key?.toLowerCase()] || defaultValue}
                      </span>
                      {expandableCells[`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`] && (
                        <button
                          className={[style.expandCellButton, expandableCells[`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`]?.expanded && style.expanded].join(
                            ' ',
                          )}
                          onClick={() => expandCell(`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`)}>
                          {expandableCells[`truncated-cell-${col.codename}-${entityYearIndex}-${rowIndex}`]?.expanded ? 'Less' : 'More'}
                        </button>
                      )}
                    </td>
                  );
                });
              }

              return (
                <td key={`${colGroup.codename}-${rowIndex}`} className={backgroundGreenColor ? style.greenBackground : style.whiteBackground}>
                  <span
                    id={`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`}
                    className={style.limitTextLines}
                    style={expandableCells[`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`]?.expanded ? { '--MAX-LINE': 'none' } : {}}>
                    {colGroup.concatFields && Array.isArray(colGroup.concatFields)
                      ? rowData['name'] || colGroup.concatFields.map((fieldName) => rowData[fieldName?.toLowerCase()]).join(' ')
                      : colGroup.key?.toLowerCase() !== 'year' && colGroup.key?.toLowerCase() !== 'organisation abn'
                      ? formatStringNumberWithCommas(rowData[colGroup.key?.toLowerCase()]) || defaultValue
                      : rowData[colGroup.key?.toLowerCase()] || defaultValue}
                  </span>
                  {expandableCells[`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`] && (
                    <button
                      className={[style.expandCellButton, expandableCells[`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`]?.expanded && style.expanded].join(
                        ' ',
                      )}
                      onClick={() => expandCell(`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`)}>
                      {expandableCells[`truncated-cell-${colGroup.codename}-${entityYearIndex}-${rowIndex}`]?.expanded ? 'Less' : 'More'}
                    </button>
                  )}
                </td>
              );
            })}
          </tr>
        );
      });
    });
  };
  return (
    <table className={[style.dataSetTable, style.appendTable].join(' ')}>
      <thead>
        <tr>{appendTableColumnsHeading()}</tr>
        <tr>{appendTableSubColumnsHeading()}</tr>
      </thead>
      <tbody>{renderAppendTableRows()}</tbody>
    </table>
  );
});

DataSetsTable.propTypes = {
  webTableData: PropTypes.object.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFixedTable: PropTypes.bool,
  expandAllCells: PropTypes.bool,
  updateParentData: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default DataSetsTable;
